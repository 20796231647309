@media screen and (max-width: 800px) {
    div.ImageCarousel {
        width: 320px;
    }
}

.ImageCarousel {
    width: 450px;
}

.ind {
    width: 10px;
    height: 10px;
    list-style: none;
    border: 2px solid rgb(29, 29, 29);
    background-color: rgb(53, 53, 53);
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 100;
    margin-right: 5px;
    margin-left: 5px;
}

.ind.active {
    background-color: white;
    transition: 500ms 200ms;
}