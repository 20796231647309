@media screen and (max-width:800px) {
    div.SelectionBarWrapper {
        display: none;
    }
    div.LookbookImageContainer {
        width: 100%;
    }
    div.OpenMenuContainer {
        display: flex;
    }
    ul.SelectionBarList {
        padding: 0px;
        margin: 0px;
    }
    ul.InnersList {
        padding: 0px;
        margin: 10px 0px 10px 10px;
        font-size: 12px;
    }
    li.InnersListItem {
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 800px) {
    div.SelectionBarWrapperInside {
        display: none;
    }
}

@font-face {
    font-family: Helvetica;
    src: url("../../Assets/Fonts/Helvetica-Bold-Font.ttf");
    font-weight: bold;
}


/* @font-face {
    font-family: Helvetica Italic;
    src: url("../../Assets/Fonts/Helvetica-Bold-Italic.ttf");
    font-weight: bold;
} */

.LookbookPage {
    font-family: Helvetica;
}

.OpenMenuContainer {
    display: none;
    justify-content: center;
    margin-bottom: 10px;
}

.OpenMenuButton {
    all: unset;
    text-align: center;
    font-family: Helvetica;
    width: 50%;
    padding: 0px 5px;
    background-color: none;
    border-radius: 5px;
    color: #e1b6d9;
}

.Wrapper {
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.SelectionBarList {
    list-style: none;
}

.SelectionBar {
    display: flex;
    justify-content: center;
}

.row {
    padding: 5px;
    cursor: pointer;
}

.row:hover {
    color: #e1b6d9;
}

.row:focus {
    color: #e1b6d9;
}

.TitleContainer {
    font-size: 17px;
}

.Inners {
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    color: black;
}

.InnersList {
    list-style: none;
}

.InnersList {
    font-size: 15px;
}

.InnersListItem:hover {
    color: #e1b6d9;
    text-decoration: underline;
}

.LookbookImageContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 50%;
    justify-content: center;
}

.OuterImageList {
    list-style: none;
    display: flex;
}

.PreviewImageList {
    display: flex;
}

.PreviewImage {
    width: 300px;
    align-self: start;
}

.LookbookImageList {
    display: flex;
}

.LookbookImage {
    width: 200px;
}