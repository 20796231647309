@media screen and (max-width: 800px) {
    img.TitleImage {
        height: 40px;
    }
}

.Navbar {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.TitleImage {
    height: 50px;
}