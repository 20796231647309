@media screen and (max-width: 800px) {
    img.MainTitleImage {
        height: 60px;
    }
    div.Top {
        padding-top: 25px;
    }
    p.ButtonText {
        font-size: 18px;
    }
    div.Buttons {
        padding: 0px;
    }
    li.Button {
        margin: 0px;
    }
    div.MainButtonContainer {
        display: flex;
        align-items: center;
    }
    img.ShoppingCartGIF {
        width: 25px;
    }
    a.itemLink {
        font-size: 14px;
    }
    li.contact {
        margin-top: 10px;
    }
    div.Home {
        margin-top: 70px;
        height: 75vh;
    }
    img.MainImage {
        width: 98%;
        object-fit: cover;
    }
    div.WyredIn-trailer iframe {
        width: 98%;
        height: 98%;
        left: 1%
    }
    li.dropdownItem {
        margin-bottom: 5px;
    }
    div.ImageContainer {
        margin-top: 25px;
        display: inline-block;
        text-align: center;
    }
}

@font-face {
    font-family: Helvetica Round;
    src: url("../Assets/Fonts/Helvetica-Rounded-Bold.otf");
    font-weight: bold;
}

.Home {
    height: 100%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.ImageContainer {
    display: inline-block;
    width: 600px;
}

.MainImage {
    max-width: 100%;
    max-height: 100%;
    border: none;
    outline: none;
    align-self: start;
}

.Top {
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

.MainTitleImage {
    height: 170px;
}

.ButtonText {
    font-size: 26px;
    margin: 0px;
}

.Buttons {
    font-family: Helvetica Round;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 85px;
}

.Button {
    color: black;
    text-decoration: none;
    margin: 20px 35px 10px;
    display: flex;
    align-items: center;
}

.MainButtonContainer {
    display: flex;
    align-items: center;
}

.ShoppingCartGIF {
    margin: 0px;
    margin-right: 5px;
    width: 40px;
}

.dropdown {
    display: block;
}

.dropdown_menu {
    min-width: max-content;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.65s;
    position: absolute;
    justify-content: center;
    margin: 0px;
    padding: 0px;
}

.dropdown:hover .dropdown_menu,
.dropdown:focus .dropdown_menu,
.dropdown:focus-within .dropdown_menu {
    max-height: 200px;
}

.itemLink {
    text-decoration: none;
    color: #e1b6d9;
    padding: 0;
    font-size: 20px;
}

.itemLink:hover {
    color: #cca4c5;
}

.WyredIn-trailer {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.WyredIn-trailer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}