@media screen and (max-width: 800px) {
    div.Footer {
        height: 10vh;
    }
    img.WyredInLogo {
        width: 200px;
    }
    img.SocialLogo {
        width: 30px;
        max-width: 100%;
        margin-left: 5px;
        margin-right: 5px;
    }
    div.UnderText {
        font-size: 10px;
    }
    div.Credits h5 {
        font-size: 8px;
        margin-bottom: 5px;
    }
}

@font-face {
    font-family: Helvetica Round;
    src: url("../../Assets/Fonts/Helvetica-Rounded-Bold.otf");
    font-weight: bold;
}

.Footer {
    display: flex;
    justify-content: center;
    height: 20vh;
    margin-bottom: 20px;
    margin-top: 50px;
    font-family: Helvetica Round;
}

.WyredInContainer {
    display: flex;
    justify-content: center;
}

.WyredInLogo {
    width: 350px;
    margin-bottom: 5px;
}

.Logos {
    display: flex;
    justify-content: center;
}

.SocialLogo {
    max-width: 55%;
    max-height: 100%;
    margin-left: 8px;
    margin-right: 8px;
    align-self: center;
}

.UnderText {
    display: flex;
    justify-content: center;
}

.LogoImageContainer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Credits {
    display: flex;
    justify-content: center;
}

.Credits h5 {
    margin: 0px;
    font-size: 12px;
}