@font-face {
    font-family: Helvetica;
    src: url("./Assets/Fonts/Helvetica-Bold-Font.ttf");
    font-weight: bold;
}

body {
    font-family: Helvetica;
    background-color: #fefcfe;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;
    margin: 0px;
    padding: 0px;
}

html {
    margin: 0px;
    padding: 0px;
}